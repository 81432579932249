export default {
    namespaced: true,
    state: {
        showHeight: window.innerHeight,   //实时屏幕高度
        hideshow: true
    },
    actions: {
      async updateShowHeight({ state, dispatch }, showHeight) {
          // store 赋值
          state.showHeight = showHeight;
          localStorage.setItem("showHeight", JSON.stringify(showHeight));
      },
      async updateHideshow({ state, dispatch }, hideshow) {
        // store 赋值
        state.hideshow = hideshow;
        localStorage.setItem("hideshow", JSON.stringify(hideshow));
      },
    }
  };
  