import { request } from "@/api/service";
import { mergeWith } from "lodash";
export default {
  namespaced: true,
  state: {
    // 用户信息
    info:
      (localStorage.getItem("user_info") &&
        JSON.parse(localStorage.getItem("user_info"))) ||
      {}
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set ({ state, dispatch }, info) {
      // store 赋值
      state.info = info;
      localStorage.setItem("user_info", JSON.stringify(info));
    },
    async setToken ({ state, dispatch }, { token, mobile }) {
      state.info.token = token
      state.info.mobile = mobile
      localStorage.setItem("user_info", JSON.stringify(state.info));
      await dispatch("setInfo")
    },
    async setInfo ({ state, dispatch }) {
      if (!(state.info && state.info.token)) {
        return false
      }
      const info = await request({
        url: `/acs-rescue/rscCompanyDriver/queryByMobile`,
        method: "post",
        data: { mobile: state.info.mobile },
      });
      if (info) {
        mergeWith(state.info, info)
        state.info.roleList = info.roleList
        localStorage.setItem("user_info", JSON.stringify(state.info));
      }
    }
  },
};
