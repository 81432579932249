import Layout from "@/views/driver/index.vue";
const meta = {
  auth: false,
};
export default [
  {
    path: "/components",
    name: "Components",
    component: Layout,
    children: [
      {
        path: "locationSearch",
        name: "LocationSearch",
        component: () => import("@/components/locationSearch.vue"),
        meta: {
          ...meta,
          title: "选择位置",
        },
      },
      {
        path: "amap",
        name: "AMap",
        component: () => import("@/components/AMap.vue"),
        meta: {
          ...meta,
          title: "高德地图",
        },
      },
    ],
  },
];
