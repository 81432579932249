import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./vant";
import "@/assets/style/index.scss";

Vue.config.productionTip = false;
import * as filters from "./filters";
// 全局注册过滤器
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
