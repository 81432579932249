import Layout from "@/views/driver/index.vue";
const meta = {
  auth: false,
};
export default [
  {
    path: "/customer",
    name: "Customer",
    component: Layout,
    children: [
      {
        path: "viewDriver",
        name: "ViewDriver",
        component: () => import("@/views/customer/viewDriver.vue"),
        meta: {
          ...meta,
          title: "司机轨迹",
        }
      },
      {
        path: "appraise",
        name: "Appraise",
        component: () => import("@/views/customer/appraise.vue"),
        meta: {
          ...meta,
          title: "评价",
        }
      }
    ]
  }
];
