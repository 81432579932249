//  格式化方法
export function thousandSeparator(num) {
  if(!num){
    return ''
  }
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,"); //千分位格式化
}
//  格式化方法F
export function thousandSeparatortoFixed(num,lenght) {
  if(!num){
    return ''
  }
  const val=Number(num).toFixed(lenght)
  return (val + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,"); //千分位格式化
}
