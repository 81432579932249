import Layout from "@/views/driver/index.vue";
const meta = {
  auth: true,
};
export default [
  {
    path: "/driver/:token",
    name: "Driver",
    component: Layout,
    children: [
      {
        path: "order/goConfirm",
        name: "GoConfirm",
        component: () => import("@/views/driver/order/modules/goConfirm.vue"),
        meta: {
          ...meta,
          title: "出发确认",
        }
      }
    ]
  }
];
