import { queryDictContentListAll } from "@/api/common/dict";
const dictMapParam = {
  ORDER_LIST_STATUS: [
    {
      label: '待收货',
      value: '00'
    },
    {
      label: '待付款',
      value: '04'
    },
    {
      label: '已完成',
      value: '05'
    }
  ],
  ORDER_STATUS: [
    {
      label: '待备料',
      value: '01'
    },
    {
      label: '待加工',
      value: '02'
    },
    {
      label: '待检查',
      value: '03'
    },
    {
      label: '待付款',
      value: '04'
    },
    {
      label: '已完成',
      value: '05'
    }
  ]

};
export default {
  namespaced: true,
  state: {
    // 字典数据源
    dictSource: [],
    // 字典数据对象
    dictMap: dictMapParam,
  },
  actions: {
    async setDict ({ state, dispatch }, projectCode) {
      // 字典初始化
      // 初始化dictMap
      const dictMap = await queryDictContentListAll("afis-agency");
      let dictData = Object.assign(state.dictMap, dictMap);
      for (let i in dictData) {
        dictData[i].map((item) => {
          item.text = item.label;
        });
      }
      state.dictMap = dictData;
      localStorage.setItem(
        'rescue_dict_map',
        JSON.stringify(state.dictMap)
      );
    },
    async loadDict ({ state, dispatch }) {
      // 字典初始化
      // 初始化dictMap
      if (!localStorage.getItem('rescue_dict_map')) {
        const dictMap = await queryDictContentListAll("afis-agency");
        let dictData = Object.assign(state.dictMap, dictMap);
        for (let i in dictData) {
          dictData[i].map((item) => {
            item.text = item.label;
          });
        }
        state.dictMap = dictData;
        localStorage.setItem(
          'rescue_dict_map',
          JSON.stringify(state.dictMap)
        );
      } else {
        state.dictMap = Object.assign(
          state.dictMap,
          JSON.parse(localStorage.getItem('rescue_dict_map'))
        );
      }
    },
  },
};
