<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
if (process.env.VUE_APP_MODE !== "production") {
  //   var vConsole = new VConsole();
}
export default {
  methods: {},
  created() {},
};
</script>
<style lang="scss">
.van-button--primary {
  background-color: #12851f !important;
  border: 1px solid #12851f !important;
}
// #app {
//   background: rgb(239, 239, 249);
// }
</style>
