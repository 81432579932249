import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import Driver from "./modules/driver";
import Customer from "./modules/customer";
import Components from "./modules/components";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [...Driver, ...Customer, ...Components];

const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, from, next) => {
  // 判断任务详情展示
  const autoFlag = to.meta && to.meta.auth;
  if (autoFlag) {
    const userInfo = store.getters.userInfo;
    const { token } = to.params;
    const { mobile } = to.query;
    if (token && userInfo.token !== token) {
      userInfo.token = token;
      userInfo.mobile = mobile;
      store.dispatch("user/set", userInfo);
    }
  }
  document.title = (to.meta && to.meta.title) || "";
  next();
});
export default router;
